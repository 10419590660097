import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import * as Types from '../../__generated__/types';
const defaultOptions =  {}
export const QuoteFragment = gql`
    fragment QuoteFragment on Quote {
  id
  quoteNumber
  total
  contact {
    id
    fullName
  }
  contactAddress {
    id
  }
  jobAddress {
    id
  }
  quotedAt
  lineItems {
    id
    description
    amount
    quantity
    taxPercentage
  }
  jobId
  hasDiscount
  discountType
  discountAmount
  additionalComment
}
    `;
export const AuthenticateUserDocument = gql`
    mutation authenticateUser($loginToken: String!) {
  authenticateUser(loginToken: $loginToken) {
    accessToken
    refreshToken
  }
}
    `;
export type AuthenticateUserMutationFn = Apollo.MutationFunction<Types.AuthenticateUser, Types.AuthenticateUserVariables>;

/**
 * __useAuthenticateUser__
 *
 * To run a mutation, you first call `useAuthenticateUser` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthenticateUser` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authenticateUser, { data, loading, error }] = useAuthenticateUser({
 *   variables: {
 *      loginToken: // value for 'loginToken'
 *   },
 * });
 */
export function useAuthenticateUser(baseOptions?: Apollo.MutationHookOptions<Types.AuthenticateUser, Types.AuthenticateUserVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AuthenticateUser, Types.AuthenticateUserVariables>(AuthenticateUserDocument, options);
      }
export type AuthenticateUserHookResult = ReturnType<typeof useAuthenticateUser>;
export type AuthenticateUserMutationResult = Apollo.MutationResult<Types.AuthenticateUser>;
export type AuthenticateUserMutationOptions = Apollo.BaseMutationOptions<Types.AuthenticateUser, Types.AuthenticateUserVariables>;
export const CloseJobDocument = gql`
    mutation CloseJob($closingNotes: String!, $closeJobId: ID!) {
  closeJob(closingNotes: $closingNotes, id: $closeJobId) {
    job {
      id
    }
  }
}
    `;
export type CloseJobMutationFn = Apollo.MutationFunction<Types.CloseJob, Types.CloseJobVariables>;

/**
 * __useCloseJob__
 *
 * To run a mutation, you first call `useCloseJob` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseJob` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeJob, { data, loading, error }] = useCloseJob({
 *   variables: {
 *      closingNotes: // value for 'closingNotes'
 *      closeJobId: // value for 'closeJobId'
 *   },
 * });
 */
export function useCloseJob(baseOptions?: Apollo.MutationHookOptions<Types.CloseJob, Types.CloseJobVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CloseJob, Types.CloseJobVariables>(CloseJobDocument, options);
      }
export type CloseJobHookResult = ReturnType<typeof useCloseJob>;
export type CloseJobMutationResult = Apollo.MutationResult<Types.CloseJob>;
export type CloseJobMutationOptions = Apollo.BaseMutationOptions<Types.CloseJob, Types.CloseJobVariables>;
export const CompleteJobDocument = gql`
    mutation CompleteJob($id: ID!) {
  completeJob(id: $id) {
    job {
      id
      completedAt
      completedBy
    }
  }
}
    `;
export type CompleteJobMutationFn = Apollo.MutationFunction<Types.CompleteJob, Types.CompleteJobVariables>;

/**
 * __useCompleteJob__
 *
 * To run a mutation, you first call `useCompleteJob` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteJob` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeJob, { data, loading, error }] = useCompleteJob({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompleteJob(baseOptions?: Apollo.MutationHookOptions<Types.CompleteJob, Types.CompleteJobVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CompleteJob, Types.CompleteJobVariables>(CompleteJobDocument, options);
      }
export type CompleteJobHookResult = ReturnType<typeof useCompleteJob>;
export type CompleteJobMutationResult = Apollo.MutationResult<Types.CompleteJob>;
export type CompleteJobMutationOptions = Apollo.BaseMutationOptions<Types.CompleteJob, Types.CompleteJobVariables>;
export const CreateQuoteDocument = gql`
    mutation CreateQuote($input: QuoteInput!) {
  createQuote(input: $input) {
    ...QuoteFragment
  }
}
    ${QuoteFragment}`;
export type CreateQuoteMutationFn = Apollo.MutationFunction<Types.CreateQuote, Types.CreateQuoteVariables>;

/**
 * __useCreateQuote__
 *
 * To run a mutation, you first call `useCreateQuote` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuote` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuote, { data, loading, error }] = useCreateQuote({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateQuote(baseOptions?: Apollo.MutationHookOptions<Types.CreateQuote, Types.CreateQuoteVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateQuote, Types.CreateQuoteVariables>(CreateQuoteDocument, options);
      }
export type CreateQuoteHookResult = ReturnType<typeof useCreateQuote>;
export type CreateQuoteMutationResult = Apollo.MutationResult<Types.CreateQuote>;
export type CreateQuoteMutationOptions = Apollo.BaseMutationOptions<Types.CreateQuote, Types.CreateQuoteVariables>;
export const DeclineJobDocument = gql`
    mutation DeclineJob($id: ID!) {
  declineJob(id: $id) {
    id
  }
}
    `;
export type DeclineJobMutationFn = Apollo.MutationFunction<Types.DeclineJob, Types.DeclineJobVariables>;

/**
 * __useDeclineJob__
 *
 * To run a mutation, you first call `useDeclineJob` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeclineJob` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [declineJob, { data, loading, error }] = useDeclineJob({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeclineJob(baseOptions?: Apollo.MutationHookOptions<Types.DeclineJob, Types.DeclineJobVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeclineJob, Types.DeclineJobVariables>(DeclineJobDocument, options);
      }
export type DeclineJobHookResult = ReturnType<typeof useDeclineJob>;
export type DeclineJobMutationResult = Apollo.MutationResult<Types.DeclineJob>;
export type DeclineJobMutationOptions = Apollo.BaseMutationOptions<Types.DeclineJob, Types.DeclineJobVariables>;
export const GiveConsentDocument = gql`
    mutation GiveConsent($consent: [Consent!]!) {
  giveConsent(consent: $consent) {
    tradesperson {
      id
      hasAcceptedLatestTerms
    }
  }
}
    `;
export type GiveConsentMutationFn = Apollo.MutationFunction<Types.GiveConsent, Types.GiveConsentVariables>;

/**
 * __useGiveConsent__
 *
 * To run a mutation, you first call `useGiveConsent` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGiveConsent` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [giveConsent, { data, loading, error }] = useGiveConsent({
 *   variables: {
 *      consent: // value for 'consent'
 *   },
 * });
 */
export function useGiveConsent(baseOptions?: Apollo.MutationHookOptions<Types.GiveConsent, Types.GiveConsentVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.GiveConsent, Types.GiveConsentVariables>(GiveConsentDocument, options);
      }
export type GiveConsentHookResult = ReturnType<typeof useGiveConsent>;
export type GiveConsentMutationResult = Apollo.MutationResult<Types.GiveConsent>;
export type GiveConsentMutationOptions = Apollo.BaseMutationOptions<Types.GiveConsent, Types.GiveConsentVariables>;
export const ImpersonateUserDocument = gql`
    mutation ImpersonateUser($emailAddress: String!) {
  impersonateUser(emailAddress: $emailAddress) {
    accessToken
    refreshToken
  }
}
    `;
export type ImpersonateUserMutationFn = Apollo.MutationFunction<Types.ImpersonateUser, Types.ImpersonateUserVariables>;

/**
 * __useImpersonateUser__
 *
 * To run a mutation, you first call `useImpersonateUser` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImpersonateUser` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [impersonateUser, { data, loading, error }] = useImpersonateUser({
 *   variables: {
 *      emailAddress: // value for 'emailAddress'
 *   },
 * });
 */
export function useImpersonateUser(baseOptions?: Apollo.MutationHookOptions<Types.ImpersonateUser, Types.ImpersonateUserVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ImpersonateUser, Types.ImpersonateUserVariables>(ImpersonateUserDocument, options);
      }
export type ImpersonateUserHookResult = ReturnType<typeof useImpersonateUser>;
export type ImpersonateUserMutationResult = Apollo.MutationResult<Types.ImpersonateUser>;
export type ImpersonateUserMutationOptions = Apollo.BaseMutationOptions<Types.ImpersonateUser, Types.ImpersonateUserVariables>;
export const NoAccessJobsDocument = gql`
    mutation NoAccessJobs($ids: [ID!]!) {
  noAccessJobs(ids: $ids) {
    ids
  }
}
    `;
export type NoAccessJobsMutationFn = Apollo.MutationFunction<Types.NoAccessJobs, Types.NoAccessJobsVariables>;

/**
 * __useNoAccessJobs__
 *
 * To run a mutation, you first call `useNoAccessJobs` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNoAccessJobs` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [noAccessJobs, { data, loading, error }] = useNoAccessJobs({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useNoAccessJobs(baseOptions?: Apollo.MutationHookOptions<Types.NoAccessJobs, Types.NoAccessJobsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.NoAccessJobs, Types.NoAccessJobsVariables>(NoAccessJobsDocument, options);
      }
export type NoAccessJobsHookResult = ReturnType<typeof useNoAccessJobs>;
export type NoAccessJobsMutationResult = Apollo.MutationResult<Types.NoAccessJobs>;
export type NoAccessJobsMutationOptions = Apollo.BaseMutationOptions<Types.NoAccessJobs, Types.NoAccessJobsVariables>;
export const RefreshAuthTokensDocument = gql`
    mutation RefreshAuthTokens($refreshToken: String!) {
  refreshAuthTokens(refreshToken: $refreshToken) {
    accessToken
    refreshToken
  }
}
    `;
export type RefreshAuthTokensMutationFn = Apollo.MutationFunction<Types.RefreshAuthTokens, Types.RefreshAuthTokensVariables>;

/**
 * __useRefreshAuthTokens__
 *
 * To run a mutation, you first call `useRefreshAuthTokens` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshAuthTokens` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshAuthTokens, { data, loading, error }] = useRefreshAuthTokens({
 *   variables: {
 *      refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useRefreshAuthTokens(baseOptions?: Apollo.MutationHookOptions<Types.RefreshAuthTokens, Types.RefreshAuthTokensVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RefreshAuthTokens, Types.RefreshAuthTokensVariables>(RefreshAuthTokensDocument, options);
      }
export type RefreshAuthTokensHookResult = ReturnType<typeof useRefreshAuthTokens>;
export type RefreshAuthTokensMutationResult = Apollo.MutationResult<Types.RefreshAuthTokens>;
export type RefreshAuthTokensMutationOptions = Apollo.BaseMutationOptions<Types.RefreshAuthTokens, Types.RefreshAuthTokensVariables>;
export const RequestLoginLinkDocument = gql`
    mutation requestLoginLink($emailAddress: String!) {
  requestLoginLink(emailAddress: $emailAddress) {
    emailAddress
  }
}
    `;
export type RequestLoginLinkMutationFn = Apollo.MutationFunction<Types.RequestLoginLink, Types.RequestLoginLinkVariables>;

/**
 * __useRequestLoginLink__
 *
 * To run a mutation, you first call `useRequestLoginLink` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestLoginLink` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestLoginLink, { data, loading, error }] = useRequestLoginLink({
 *   variables: {
 *      emailAddress: // value for 'emailAddress'
 *   },
 * });
 */
export function useRequestLoginLink(baseOptions?: Apollo.MutationHookOptions<Types.RequestLoginLink, Types.RequestLoginLinkVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RequestLoginLink, Types.RequestLoginLinkVariables>(RequestLoginLinkDocument, options);
      }
export type RequestLoginLinkHookResult = ReturnType<typeof useRequestLoginLink>;
export type RequestLoginLinkMutationResult = Apollo.MutationResult<Types.RequestLoginLink>;
export type RequestLoginLinkMutationOptions = Apollo.BaseMutationOptions<Types.RequestLoginLink, Types.RequestLoginLinkVariables>;
export const RequestSignedJobSheetAssetUploadUrlDocument = gql`
    mutation RequestSignedJobSheetAssetUploadURL($jobSheetId: ID!, $key: String!) {
  requestSignedJobSheetAssetUploadURL(jobSheetId: $jobSheetId, key: $key) {
    destinationUrl
    uploadUrl
    signedFields
  }
}
    `;
export type RequestSignedJobSheetAssetUploadUrlMutationFn = Apollo.MutationFunction<Types.RequestSignedJobSheetAssetUploadUrl, Types.RequestSignedJobSheetAssetUploadUrlVariables>;

/**
 * __useRequestSignedJobSheetAssetUploadUrl__
 *
 * To run a mutation, you first call `useRequestSignedJobSheetAssetUploadUrl` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestSignedJobSheetAssetUploadUrl` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestSignedJobSheetAssetUploadUrl, { data, loading, error }] = useRequestSignedJobSheetAssetUploadUrl({
 *   variables: {
 *      jobSheetId: // value for 'jobSheetId'
 *      key: // value for 'key'
 *   },
 * });
 */
export function useRequestSignedJobSheetAssetUploadUrl(baseOptions?: Apollo.MutationHookOptions<Types.RequestSignedJobSheetAssetUploadUrl, Types.RequestSignedJobSheetAssetUploadUrlVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RequestSignedJobSheetAssetUploadUrl, Types.RequestSignedJobSheetAssetUploadUrlVariables>(RequestSignedJobSheetAssetUploadUrlDocument, options);
      }
export type RequestSignedJobSheetAssetUploadUrlHookResult = ReturnType<typeof useRequestSignedJobSheetAssetUploadUrl>;
export type RequestSignedJobSheetAssetUploadUrlMutationResult = Apollo.MutationResult<Types.RequestSignedJobSheetAssetUploadUrl>;
export type RequestSignedJobSheetAssetUploadUrlMutationOptions = Apollo.BaseMutationOptions<Types.RequestSignedJobSheetAssetUploadUrl, Types.RequestSignedJobSheetAssetUploadUrlVariables>;
export const CreateSelfBillingDocumentDocument = gql`
    mutation CreateSelfBillingDocument {
  createUnsignedSelfBillingAgreement {
    preSignedUrl
    documentId
  }
}
    `;
export type CreateSelfBillingDocumentMutationFn = Apollo.MutationFunction<Types.CreateSelfBillingDocument, Types.CreateSelfBillingDocumentVariables>;

/**
 * __useCreateSelfBillingDocument__
 *
 * To run a mutation, you first call `useCreateSelfBillingDocument` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSelfBillingDocument` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSelfBillingDocument, { data, loading, error }] = useCreateSelfBillingDocument({
 *   variables: {
 *   },
 * });
 */
export function useCreateSelfBillingDocument(baseOptions?: Apollo.MutationHookOptions<Types.CreateSelfBillingDocument, Types.CreateSelfBillingDocumentVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateSelfBillingDocument, Types.CreateSelfBillingDocumentVariables>(CreateSelfBillingDocumentDocument, options);
      }
export type CreateSelfBillingDocumentHookResult = ReturnType<typeof useCreateSelfBillingDocument>;
export type CreateSelfBillingDocumentMutationResult = Apollo.MutationResult<Types.CreateSelfBillingDocument>;
export type CreateSelfBillingDocumentMutationOptions = Apollo.BaseMutationOptions<Types.CreateSelfBillingDocument, Types.CreateSelfBillingDocumentVariables>;
export const SignSelfBillingDocumentDocument = gql`
    mutation SignSelfBillingDocument($documentId: String!) {
  signEngineeringCompanyDocument(
    input: {documentId: $documentId, documentType: SELF_BILLING_AGREEMENT}
  ) {
    signed
  }
}
    `;
export type SignSelfBillingDocumentMutationFn = Apollo.MutationFunction<Types.SignSelfBillingDocument, Types.SignSelfBillingDocumentVariables>;

/**
 * __useSignSelfBillingDocument__
 *
 * To run a mutation, you first call `useSignSelfBillingDocument` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignSelfBillingDocument` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signSelfBillingDocument, { data, loading, error }] = useSignSelfBillingDocument({
 *   variables: {
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useSignSelfBillingDocument(baseOptions?: Apollo.MutationHookOptions<Types.SignSelfBillingDocument, Types.SignSelfBillingDocumentVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SignSelfBillingDocument, Types.SignSelfBillingDocumentVariables>(SignSelfBillingDocumentDocument, options);
      }
export type SignSelfBillingDocumentHookResult = ReturnType<typeof useSignSelfBillingDocument>;
export type SignSelfBillingDocumentMutationResult = Apollo.MutationResult<Types.SignSelfBillingDocument>;
export type SignSelfBillingDocumentMutationOptions = Apollo.BaseMutationOptions<Types.SignSelfBillingDocument, Types.SignSelfBillingDocumentVariables>;
export const CreateServiceLevelAgreementDocument = gql`
    mutation CreateServiceLevelAgreement($input: CreateUnsignedServiceLevelAgreementInput!) {
  createUnsignedServiceLevelAgreement(input: $input) {
    signingUrl
    documentId
  }
}
    `;
export type CreateServiceLevelAgreementMutationFn = Apollo.MutationFunction<Types.CreateServiceLevelAgreement, Types.CreateServiceLevelAgreementVariables>;

/**
 * __useCreateServiceLevelAgreement__
 *
 * To run a mutation, you first call `useCreateServiceLevelAgreement` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateServiceLevelAgreement` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createServiceLevelAgreement, { data, loading, error }] = useCreateServiceLevelAgreement({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateServiceLevelAgreement(baseOptions?: Apollo.MutationHookOptions<Types.CreateServiceLevelAgreement, Types.CreateServiceLevelAgreementVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateServiceLevelAgreement, Types.CreateServiceLevelAgreementVariables>(CreateServiceLevelAgreementDocument, options);
      }
export type CreateServiceLevelAgreementHookResult = ReturnType<typeof useCreateServiceLevelAgreement>;
export type CreateServiceLevelAgreementMutationResult = Apollo.MutationResult<Types.CreateServiceLevelAgreement>;
export type CreateServiceLevelAgreementMutationOptions = Apollo.BaseMutationOptions<Types.CreateServiceLevelAgreement, Types.CreateServiceLevelAgreementVariables>;
export const ConfirmSignedServiceLevelAgreementDocument = gql`
    mutation ConfirmSignedServiceLevelAgreement($documentId: String!) {
  signEngineeringCompanyDocument(
    input: {documentId: $documentId, documentType: SERVICE_LEVEL_AGREEMENT}
  ) {
    signed
  }
}
    `;
export type ConfirmSignedServiceLevelAgreementMutationFn = Apollo.MutationFunction<Types.ConfirmSignedServiceLevelAgreement, Types.ConfirmSignedServiceLevelAgreementVariables>;

/**
 * __useConfirmSignedServiceLevelAgreement__
 *
 * To run a mutation, you first call `useConfirmSignedServiceLevelAgreement` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmSignedServiceLevelAgreement` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmSignedServiceLevelAgreement, { data, loading, error }] = useConfirmSignedServiceLevelAgreement({
 *   variables: {
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useConfirmSignedServiceLevelAgreement(baseOptions?: Apollo.MutationHookOptions<Types.ConfirmSignedServiceLevelAgreement, Types.ConfirmSignedServiceLevelAgreementVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ConfirmSignedServiceLevelAgreement, Types.ConfirmSignedServiceLevelAgreementVariables>(ConfirmSignedServiceLevelAgreementDocument, options);
      }
export type ConfirmSignedServiceLevelAgreementHookResult = ReturnType<typeof useConfirmSignedServiceLevelAgreement>;
export type ConfirmSignedServiceLevelAgreementMutationResult = Apollo.MutationResult<Types.ConfirmSignedServiceLevelAgreement>;
export type ConfirmSignedServiceLevelAgreementMutationOptions = Apollo.BaseMutationOptions<Types.ConfirmSignedServiceLevelAgreement, Types.ConfirmSignedServiceLevelAgreementVariables>;
export const SetJobNotesDocument = gql`
    mutation SetJobNotes($id: ID!, $notes: String!) {
  setJobNotes(id: $id, notes: $notes) {
    job {
      id
      notes
    }
  }
}
    `;
export type SetJobNotesMutationFn = Apollo.MutationFunction<Types.SetJobNotes, Types.SetJobNotesVariables>;

/**
 * __useSetJobNotes__
 *
 * To run a mutation, you first call `useSetJobNotes` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetJobNotes` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setJobNotes, { data, loading, error }] = useSetJobNotes({
 *   variables: {
 *      id: // value for 'id'
 *      notes: // value for 'notes'
 *   },
 * });
 */
export function useSetJobNotes(baseOptions?: Apollo.MutationHookOptions<Types.SetJobNotes, Types.SetJobNotesVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SetJobNotes, Types.SetJobNotesVariables>(SetJobNotesDocument, options);
      }
export type SetJobNotesHookResult = ReturnType<typeof useSetJobNotes>;
export type SetJobNotesMutationResult = Apollo.MutationResult<Types.SetJobNotes>;
export type SetJobNotesMutationOptions = Apollo.BaseMutationOptions<Types.SetJobNotes, Types.SetJobNotesVariables>;
export const SetJobSheetReportDocument = gql`
    mutation SetJobSheetReport($id: ID!, $report: JSON!) {
  setJobSheetReport(id: $id, report: $report) {
    id
    report
  }
}
    `;
export type SetJobSheetReportMutationFn = Apollo.MutationFunction<Types.SetJobSheetReport, Types.SetJobSheetReportVariables>;

/**
 * __useSetJobSheetReport__
 *
 * To run a mutation, you first call `useSetJobSheetReport` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetJobSheetReport` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setJobSheetReport, { data, loading, error }] = useSetJobSheetReport({
 *   variables: {
 *      id: // value for 'id'
 *      report: // value for 'report'
 *   },
 * });
 */
export function useSetJobSheetReport(baseOptions?: Apollo.MutationHookOptions<Types.SetJobSheetReport, Types.SetJobSheetReportVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SetJobSheetReport, Types.SetJobSheetReportVariables>(SetJobSheetReportDocument, options);
      }
export type SetJobSheetReportHookResult = ReturnType<typeof useSetJobSheetReport>;
export type SetJobSheetReportMutationResult = Apollo.MutationResult<Types.SetJobSheetReport>;
export type SetJobSheetReportMutationOptions = Apollo.BaseMutationOptions<Types.SetJobSheetReport, Types.SetJobSheetReportVariables>;
export const SubmitJobSheetDocument = gql`
    mutation SubmitJobSheet($id: ID!) {
  submitJobSheet(id: $id) {
    id
    submittedAt
  }
}
    `;
export type SubmitJobSheetMutationFn = Apollo.MutationFunction<Types.SubmitJobSheet, Types.SubmitJobSheetVariables>;

/**
 * __useSubmitJobSheet__
 *
 * To run a mutation, you first call `useSubmitJobSheet` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitJobSheet` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitJobSheet, { data, loading, error }] = useSubmitJobSheet({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSubmitJobSheet(baseOptions?: Apollo.MutationHookOptions<Types.SubmitJobSheet, Types.SubmitJobSheetVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SubmitJobSheet, Types.SubmitJobSheetVariables>(SubmitJobSheetDocument, options);
      }
export type SubmitJobSheetHookResult = ReturnType<typeof useSubmitJobSheet>;
export type SubmitJobSheetMutationResult = Apollo.MutationResult<Types.SubmitJobSheet>;
export type SubmitJobSheetMutationOptions = Apollo.BaseMutationOptions<Types.SubmitJobSheet, Types.SubmitJobSheetVariables>;
export const SubmitQuoteForApprovalDocument = gql`
    mutation SubmitQuoteForApproval($id: ID!) {
  submitQuoteForApproval(id: $id) {
    id
    approvalStatus
  }
}
    `;
export type SubmitQuoteForApprovalMutationFn = Apollo.MutationFunction<Types.SubmitQuoteForApproval, Types.SubmitQuoteForApprovalVariables>;

/**
 * __useSubmitQuoteForApproval__
 *
 * To run a mutation, you first call `useSubmitQuoteForApproval` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitQuoteForApproval` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitQuoteForApproval, { data, loading, error }] = useSubmitQuoteForApproval({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSubmitQuoteForApproval(baseOptions?: Apollo.MutationHookOptions<Types.SubmitQuoteForApproval, Types.SubmitQuoteForApprovalVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SubmitQuoteForApproval, Types.SubmitQuoteForApprovalVariables>(SubmitQuoteForApprovalDocument, options);
      }
export type SubmitQuoteForApprovalHookResult = ReturnType<typeof useSubmitQuoteForApproval>;
export type SubmitQuoteForApprovalMutationResult = Apollo.MutationResult<Types.SubmitQuoteForApproval>;
export type SubmitQuoteForApprovalMutationOptions = Apollo.BaseMutationOptions<Types.SubmitQuoteForApproval, Types.SubmitQuoteForApprovalVariables>;
export const UpdateUnavailableDatesDocument = gql`
    mutation UpdateUnavailableDates($partnerId: ID!, $updates: UnavailableDatesUpdates!) {
  updateUnavailableDates(partnerId: $partnerId, updates: $updates) {
    tradespersonPartner {
      id
      unavailableDates {
        id
        startDate
        endDate
        updateAutobookAvailability
      }
    }
  }
}
    `;
export type UpdateUnavailableDatesMutationFn = Apollo.MutationFunction<Types.UpdateUnavailableDates, Types.UpdateUnavailableDatesVariables>;

/**
 * __useUpdateUnavailableDates__
 *
 * To run a mutation, you first call `useUpdateUnavailableDates` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUnavailableDates` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUnavailableDates, { data, loading, error }] = useUpdateUnavailableDates({
 *   variables: {
 *      partnerId: // value for 'partnerId'
 *      updates: // value for 'updates'
 *   },
 * });
 */
export function useUpdateUnavailableDates(baseOptions?: Apollo.MutationHookOptions<Types.UpdateUnavailableDates, Types.UpdateUnavailableDatesVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateUnavailableDates, Types.UpdateUnavailableDatesVariables>(UpdateUnavailableDatesDocument, options);
      }
export type UpdateUnavailableDatesHookResult = ReturnType<typeof useUpdateUnavailableDates>;
export type UpdateUnavailableDatesMutationResult = Apollo.MutationResult<Types.UpdateUnavailableDates>;
export type UpdateUnavailableDatesMutationOptions = Apollo.BaseMutationOptions<Types.UpdateUnavailableDates, Types.UpdateUnavailableDatesVariables>;
export const ScheduleAppointmentDocument = gql`
    mutation ScheduleAppointment($appointmentId: ID!, $contactId: ID!, $startDateTime: String!, $endDateTime: String!, $bookingCommsEnabled: Boolean!) {
  scheduleAppointment(
    appointmentId: $appointmentId
    contactId: $contactId
    startDateTime: $startDateTime
    endDateTime: $endDateTime
    bookingCommsEnabled: $bookingCommsEnabled
  ) {
    appointment {
      id
      windowStart
      windowEnd
      job {
        id
        fulfillmentWindow {
          startDateTime
          endDateTime
        }
        completedAt
        bookedBy
      }
    }
    changedDays {
      id
      appointments {
        id
        windowStart
        windowEnd
      }
    }
  }
}
    `;
export type ScheduleAppointmentMutationFn = Apollo.MutationFunction<Types.ScheduleAppointment, Types.ScheduleAppointmentVariables>;

/**
 * __useScheduleAppointment__
 *
 * To run a mutation, you first call `useScheduleAppointment` within a React component and pass it any options that fit your needs.
 * When your component renders, `useScheduleAppointment` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [scheduleAppointment, { data, loading, error }] = useScheduleAppointment({
 *   variables: {
 *      appointmentId: // value for 'appointmentId'
 *      contactId: // value for 'contactId'
 *      startDateTime: // value for 'startDateTime'
 *      endDateTime: // value for 'endDateTime'
 *      bookingCommsEnabled: // value for 'bookingCommsEnabled'
 *   },
 * });
 */
export function useScheduleAppointment(baseOptions?: Apollo.MutationHookOptions<Types.ScheduleAppointment, Types.ScheduleAppointmentVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ScheduleAppointment, Types.ScheduleAppointmentVariables>(ScheduleAppointmentDocument, options);
      }
export type ScheduleAppointmentHookResult = ReturnType<typeof useScheduleAppointment>;
export type ScheduleAppointmentMutationResult = Apollo.MutationResult<Types.ScheduleAppointment>;
export type ScheduleAppointmentMutationOptions = Apollo.BaseMutationOptions<Types.ScheduleAppointment, Types.ScheduleAppointmentVariables>;
export const GetInvoicePdfDocument = gql`
    mutation GetInvoicePDF($invoiceId: ID!, $preview: Boolean) {
  generateInvoicePdf(id: $invoiceId, preview: $preview) {
    invoiceUrl
  }
}
    `;
export type GetInvoicePdfMutationFn = Apollo.MutationFunction<Types.GetInvoicePdf, Types.GetInvoicePdfVariables>;

/**
 * __useGetInvoicePdf__
 *
 * To run a mutation, you first call `useGetInvoicePdf` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetInvoicePdf` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getInvoicePdf, { data, loading, error }] = useGetInvoicePdf({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *      preview: // value for 'preview'
 *   },
 * });
 */
export function useGetInvoicePdf(baseOptions?: Apollo.MutationHookOptions<Types.GetInvoicePdf, Types.GetInvoicePdfVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.GetInvoicePdf, Types.GetInvoicePdfVariables>(GetInvoicePdfDocument, options);
      }
export type GetInvoicePdfHookResult = ReturnType<typeof useGetInvoicePdf>;
export type GetInvoicePdfMutationResult = Apollo.MutationResult<Types.GetInvoicePdf>;
export type GetInvoicePdfMutationOptions = Apollo.BaseMutationOptions<Types.GetInvoicePdf, Types.GetInvoicePdfVariables>;
export const AssignJobsToMecEmployeeDocument = gql`
    mutation AssignJobsToMecEmployee($jobIds: [ID!]!, $tradespersonId: ID!) {
  assignToMecEmployee(jobIds: $jobIds, tradespersonId: $tradespersonId) {
    jobIds
  }
}
    `;
export type AssignJobsToMecEmployeeMutationFn = Apollo.MutationFunction<Types.AssignJobsToMecEmployee, Types.AssignJobsToMecEmployeeVariables>;

/**
 * __useAssignJobsToMecEmployee__
 *
 * To run a mutation, you first call `useAssignJobsToMecEmployee` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignJobsToMecEmployee` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignJobsToMecEmployee, { data, loading, error }] = useAssignJobsToMecEmployee({
 *   variables: {
 *      jobIds: // value for 'jobIds'
 *      tradespersonId: // value for 'tradespersonId'
 *   },
 * });
 */
export function useAssignJobsToMecEmployee(baseOptions?: Apollo.MutationHookOptions<Types.AssignJobsToMecEmployee, Types.AssignJobsToMecEmployeeVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AssignJobsToMecEmployee, Types.AssignJobsToMecEmployeeVariables>(AssignJobsToMecEmployeeDocument, options);
      }
export type AssignJobsToMecEmployeeHookResult = ReturnType<typeof useAssignJobsToMecEmployee>;
export type AssignJobsToMecEmployeeMutationResult = Apollo.MutationResult<Types.AssignJobsToMecEmployee>;
export type AssignJobsToMecEmployeeMutationOptions = Apollo.BaseMutationOptions<Types.AssignJobsToMecEmployee, Types.AssignJobsToMecEmployeeVariables>;
export const RemoveCompanyTradespersonDocument = gql`
    mutation RemoveCompanyTradesperson($companyId: ID!, $companyTradespersonId: ID!) {
  removeCompanyTradesperson(
    companyId: $companyId
    companyTradespersonId: $companyTradespersonId
  )
}
    `;
export type RemoveCompanyTradespersonMutationFn = Apollo.MutationFunction<Types.RemoveCompanyTradesperson, Types.RemoveCompanyTradespersonVariables>;

/**
 * __useRemoveCompanyTradesperson__
 *
 * To run a mutation, you first call `useRemoveCompanyTradesperson` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCompanyTradesperson` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCompanyTradesperson, { data, loading, error }] = useRemoveCompanyTradesperson({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      companyTradespersonId: // value for 'companyTradespersonId'
 *   },
 * });
 */
export function useRemoveCompanyTradesperson(baseOptions?: Apollo.MutationHookOptions<Types.RemoveCompanyTradesperson, Types.RemoveCompanyTradespersonVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RemoveCompanyTradesperson, Types.RemoveCompanyTradespersonVariables>(RemoveCompanyTradespersonDocument, options);
      }
export type RemoveCompanyTradespersonHookResult = ReturnType<typeof useRemoveCompanyTradesperson>;
export type RemoveCompanyTradespersonMutationResult = Apollo.MutationResult<Types.RemoveCompanyTradesperson>;
export type RemoveCompanyTradespersonMutationOptions = Apollo.BaseMutationOptions<Types.RemoveCompanyTradesperson, Types.RemoveCompanyTradespersonVariables>;
export const RemoveInvitationDocument = gql`
    mutation RemoveInvitation($id: ID!, $companyId: ID!) {
  removeTradespersonCompanyInvitation(id: $id, companyId: $companyId) {
    id
  }
}
    `;
export type RemoveInvitationMutationFn = Apollo.MutationFunction<Types.RemoveInvitation, Types.RemoveInvitationVariables>;

/**
 * __useRemoveInvitation__
 *
 * To run a mutation, you first call `useRemoveInvitation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveInvitation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeInvitation, { data, loading, error }] = useRemoveInvitation({
 *   variables: {
 *      id: // value for 'id'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useRemoveInvitation(baseOptions?: Apollo.MutationHookOptions<Types.RemoveInvitation, Types.RemoveInvitationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RemoveInvitation, Types.RemoveInvitationVariables>(RemoveInvitationDocument, options);
      }
export type RemoveInvitationHookResult = ReturnType<typeof useRemoveInvitation>;
export type RemoveInvitationMutationResult = Apollo.MutationResult<Types.RemoveInvitation>;
export type RemoveInvitationMutationOptions = Apollo.BaseMutationOptions<Types.RemoveInvitation, Types.RemoveInvitationVariables>;
export const TradespersonCompanyInvitationDocument = gql`
    mutation TradespersonCompanyInvitation($companyId: ID!, $emailAddress: String!, $fullName: String!, $phoneNumber: String!, $trades: [String!]!, $groupIds: [Int!]!) {
  inviteCompanyTradesperson(
    companyId: $companyId
    input: {emailAddress: $emailAddress, fullName: $fullName, phoneNumber: $phoneNumber, trades: $trades, groupIds: $groupIds}
  )
}
    `;
export type TradespersonCompanyInvitationMutationFn = Apollo.MutationFunction<Types.TradespersonCompanyInvitation, Types.TradespersonCompanyInvitationVariables>;

/**
 * __useTradespersonCompanyInvitation__
 *
 * To run a mutation, you first call `useTradespersonCompanyInvitation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTradespersonCompanyInvitation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tradespersonCompanyInvitation, { data, loading, error }] = useTradespersonCompanyInvitation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      emailAddress: // value for 'emailAddress'
 *      fullName: // value for 'fullName'
 *      phoneNumber: // value for 'phoneNumber'
 *      trades: // value for 'trades'
 *      groupIds: // value for 'groupIds'
 *   },
 * });
 */
export function useTradespersonCompanyInvitation(baseOptions?: Apollo.MutationHookOptions<Types.TradespersonCompanyInvitation, Types.TradespersonCompanyInvitationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.TradespersonCompanyInvitation, Types.TradespersonCompanyInvitationVariables>(TradespersonCompanyInvitationDocument, options);
      }
export type TradespersonCompanyInvitationHookResult = ReturnType<typeof useTradespersonCompanyInvitation>;
export type TradespersonCompanyInvitationMutationResult = Apollo.MutationResult<Types.TradespersonCompanyInvitation>;
export type TradespersonCompanyInvitationMutationOptions = Apollo.BaseMutationOptions<Types.TradespersonCompanyInvitation, Types.TradespersonCompanyInvitationVariables>;