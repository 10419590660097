/* eslint-disable no-restricted-imports */

import { QueryHookOptions } from '@apollo/client';

import { TradespersonDetails } from 'src/apollo/__generated__/types';
import { useAuth } from 'src/apollo/auth/AuthProvider';
import { useTradespersonDetails as useTradespersonDetailsQuery } from 'src/apollo/queries';

export const useTradespersonDetails = (
  options?: QueryHookOptions<TradespersonDetails>,
) => {
  const { isAuthReady, isAuthenticated } = useAuth();
  const query = useTradespersonDetailsQuery({
    skip: !isAuthReady || !isAuthenticated || options?.skip,
  });

  return { tradesperson: query.data?.tradesperson, ...query };
};
